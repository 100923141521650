<template>
  <v-dialog
    width="800"
    v-model="dialog"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :disabled="!siteId"
        color="primary"
        class="rounded-lg"
        v-bind="attrs"
        v-on="on"
        >
        Nueva carpeta
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        Nueva carpeta
      </v-card-title>

      <v-card-text>
        <v-text-field
          label="Nombre"
          solo
          outlined
          flat
          v-model="name"
          ></v-text-field>

        <div
          class="text-right"
          >
          <v-btn
            color="primary"
            class="rounded-lg"
            depressed
            @click="createFolder"
            >
            Crear
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { CreateFolder } from '@/graphql/mutations/repository'

export default {
  data: () => ({
    dialog: false,
    name: null
  }),

  props: {
    siteId: {
      type: String | Number,
      required: false
    }
  },

  methods: {
    createFolder () {
      this.$apollo.mutate({
        mutation: CreateFolder,
        variables: {
          input: {
            name: this.name,
            projectId: this.$route.params.id,
            siteId: this.siteId
          }
        }
      }).then ( res => {
        this.$emit('reload')
        this.name = null
        this.dialog = false
      })
    }
  }
}
</script>
